import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Tab {
  label: string;
  url: string;
  status: string;
}

interface TabsProps {
  tabs: Tab[];
  classes?: string;
}

const NavigationTabs: React.FC<TabsProps> = ({ tabs, classes }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string>(tabs[0]?.label);

  const handleTabClick = (tab: Tab) => {
    if (tab.status === 'clickable') {
      setActiveTab(tab.label);
      navigate(tab.url);
    }
  };

  return (
    <div className={`navigate-tabs flex-container flex-start ${classes}`} >
      {tabs.map((tab) => (
        <div
          key={tab.label}
          className={`tab clickable pointer ${activeTab === tab.label ? 'active' : ''} text-center`}
          onClick={() => handleTabClick(tab)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default NavigationTabs;