import { createBrowserRouter, RouteObject, Outlet } from 'react-router-dom';
import AirtableData from './components/airtable/AirtableData';
import RevenuesData from './components/airtable/RevenuesData';
import ExpensesData from './components/airtable/ExpensesData';
import PLTable2 from './components/airtable/PLTable2';
import PPETable from './components/airtable/PPETable';
import BalanceSheet from './components/airtable/BalanceSheet';
import CashFlowReport from './components/airtable/CashFlowReport';
import FinancialStatements from './components/airtable/FinancialStatements';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ProtectedRoute from './components/auth/ProtectedRoute ';
import { Dashboard } from './components/Dashboard';
import Layout from './components/layouts/Layout'; // Import the Layout component
import Base from './components/airtable/Base';
import Companies from './components/Companies';
import Collaborator from './components/Collaborator';
import Report from './components/airtable/Report';
import WorkspaceInfo from './components/workspaces/WorkspaceInfo';
import User from './components/User';
import Invite from './components/Invite';
import UpdateUser from './components/UpdateUser';
import TokenExpired from './components/redirects/TokenExpired';
import NotFound404 from './components/redirects/NotFound404';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/auth/ForgotPassword';
import ConfirmRegistration from './components/auth/ConfirmRegistration';
import Invoice from './components/Invoices/Invoice';
import Invoices from './components/Invoices/Invoices';
import LegalInfo from './components/workspaces/LegalInfo';
import ChartOfAccounts from './components/ChartOfAccounts/ChartOfAccounts';
import LeanSuccess from './pages/LeanSuccess';
import LeanFailure from './pages/LeanFailure';
import ConnectedBanksList from './components/Lean/ConnectedBanksList';
import BankAccounts from './components/Lean/BankAccounts';
import AccountProfile from './components/Lean/AccountProfile';

import BankAccount from './components/workspaces/BankAccount';
import WorkspaceAddress from './components/workspaces/WorkspaceAddress';
import Clients from './components/Clients/Clients';
import AcceptInvitation from './components/AcceptInvitation';
import BankTransaction from './components/banks/BankTransaction';
import ItineraryList from './components/TTP/ItineraryList';
import ItineraryDetail from './components/TTP/ItineraryDetail';

interface Role {
  id: number;
  name: string;
  roleSpecification: string;
}


let roleObject: Role | null = null;
const role = localStorage.getItem('role');

if (role) {
  try {
    roleObject = JSON.parse(role);
  } catch (error) {
    console.error('Error parsing role from localStorage from routes', error);
  }
}

const roleName = roleObject ? roleObject.name : '';


const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Companies />
      </ProtectedRoute>
    )
  },
  {
    path: '/workspace-info',
    element: (
      <ProtectedRoute>
        <WorkspaceInfo />
      </ProtectedRoute>
    )
  },
  {
    path: '/legal-info/:uniCode',
    element: (
      <ProtectedRoute>
        <LegalInfo />
      </ProtectedRoute>
    )
  },
  {
    path: '/workspace-info/:uniCode',
    element: (
      <ProtectedRoute>
        <WorkspaceInfo />
      </ProtectedRoute>
    )
  },
  {
    path: '/bank-account/:uniCode',
    element: (
      <ProtectedRoute>
        <BankAccount />
      </ProtectedRoute>
    )
  },
  {
    path: '/workspace-address/:uniCode',
    element: (
      <ProtectedRoute>
        <WorkspaceAddress />
      </ProtectedRoute>
    )
  },
  {
    path: '/collaborator/',
    element: (
      <ProtectedRoute>
        <Collaborator role={roleName} />
      </ProtectedRoute>
    )
  },
  {
    path: '/collaborator/:uniCode',
    element: (
      <ProtectedRoute>
        <Collaborator role={roleName} />
      </ProtectedRoute>
    )
  },
  {
    path: '/user/:userId',
    element: (
      <ProtectedRoute>
        <UpdateUser registerProp={false} />
      </ProtectedRoute>
    )
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout>
          <Outlet />
        </Layout>
      </ProtectedRoute>
    ),
    children: [
      // { path: '/', element: <Companies /> },
      { path: '/dashboard/:uniCode', element: <Dashboard /> },
      { path: '/create-invoice/:uniCodeParam', element: <Invoice qrCodeDisplay={false} /> },
      { path: '/:selectedWrokspaceUniCode/invoice/:uniCodeParam', element: <Invoice qrCodeDisplay={true} /> },
      { path: '/invoices/:uniCode', element: <Invoices /> },
      { path: '/clients/:uniCode', element: <Clients /> },
      { path: 'bank-db', element: <AirtableData tableName='BANK DB' /> },
      // { path: 'rev-db', element: <AirtableData tableName='REV BASE 2' /> },
      // { path: 'exp-db', element: <AirtableData tableName='EXP DB' /> },
      { path: 'rev-db', element: <RevenuesData workspaceId='1' /> },
      { path: 'exp-db', element: <ExpensesData workspaceId='1' /> },
      { path: 'assets-db', element: <AirtableData tableName='ASSETS DB' /> },
      { path: 'bases', element: <Base /> },
      { path: 'reports', element: <Report /> },
      { path: 'profit-and-loss', element: <PLTable2 /> },
      { path: 'ppe-table', element: <PPETable /> },
      { path: 'balance-sheet', element: <BalanceSheet /> },
      { path: 'cash-flow', element: <CashFlowReport /> },
      { path: 'financial-statements', element: <FinancialStatements /> },
      { path: 'chart-of-accounts', element: <ChartOfAccounts /> },
      { path: '/lean/success', element: <LeanSuccess /> },
      { path: '/lean/failure', element: <LeanFailure /> },
      { path: '/banks/:uniCode', element: <ConnectedBanksList /> },
      { path: '/banks/:workspaceUniCode/:bankName', element: <BankTransaction /> },
      // { path: '/banks/:entityId/accounts', element: <BankAccounts /> },
      { path: '/bank-connections/:entityId/accounts', element: <BankAccounts /> },
      { path: '/accounts/:accountId', element: <AccountProfile /> },
    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />
  },
  {
    path: '/reset-password',
    element: <ResetPassword />
  },
  {
    path: '/invite/:workspaceId',
    element: <UpdateUser registerProp={false} />
  },
  {
    path: '/accept-invitation/:workspaceId',
    element: <AcceptInvitation />
  },
  {
    path: '/confirm-registration',
    element: <ConfirmRegistration />
  },
  {
    path: '/token-expired',
    element: <TokenExpired />
  },
  {
    path: '/token-expired',
    element: <NotFound404 />
  },
  {
    path: '/ttp/itineraries',
    element: <ProtectedRoute><ItineraryList /></ProtectedRoute>
  },
  {
    path: '/ttp/itinerary/:uniItineraryCode',
    element: <ProtectedRoute><ItineraryDetail /></ProtectedRoute>
  },
];

const router = createBrowserRouter(routes);

export default router;

