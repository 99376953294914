import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from './../../reducers/reducers';
import IconButton from '@mui/material/IconButton';
import AccountBalance from '@mui/icons-material/AccountBalance'; // Bank-related icon
import FilterList from '@mui/icons-material/FilterList';
import Sort from '@mui/icons-material/Sort';

const LeanConnect: React.FC = () => {
    const user = useSelector((state: RootState) => state.userState);

    const link = async () => {
        try {
            const token = localStorage.getItem('token');

            // Get the customer access token from backend
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/lean/customer-token`,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const customerToken = response.data.customer_token;
            const leanCustomerId = response.data.lean_customer_id;
            const leanAppToken = response.data.lean_app_token;

            if (window.Lean) {
                // Calculate access_from (6 months back) and access_to (1 day in the future)
                const now = new Date();

                // Access From: 6 months back
                const accessFromDate = new Date();
                accessFromDate.setMonth(now.getMonth() - 6);

                // Access To: 1 day in the future
                const accessToDate = new Date();
                accessToDate.setDate(now.getDate() + 1);

                // Format dates to ISO strings without milliseconds and timezone
                const accessFromISOString = accessFromDate.toISOString().split('.')[0] + 'Z';
                const accessToISOString = accessToDate.toISOString().split('.')[0] + 'Z';

                window.Lean.connect({
                    app_token: leanAppToken,
                    permissions: ['identity', 'accounts', 'balance', 'transactions'],
                    customer_id: leanCustomerId,
                    sandbox: false,
                    access_token: customerToken,
                    show_consent_explanation: true,
                    fail_redirect_url: 'https://sejel.io/lean/failure',
                    success_redirect_url: 'https://sejel.io/lean/success',
                    account_type: 'business',
                    access_from: accessFromISOString,
                    access_to: accessToISOString,
                });
            } else {
                console.error('Lean SDK is not loaded.');
            }
        } catch (error) {
            console.error('Error initializing Lean SDK:', error);
        }
    };

    return (
        <div>
            <IconButton
                className="ml-4"
                onClick={link}
                color="primary" // Optional: Adjust color to match your theme
                size="large"
                aria-label="Connect Bank Account" // Accessibility label
            >
                <AccountBalance />
                <IconButton size="small">
                  <Sort />
                </IconButton>
                <IconButton size="small">
                  <FilterList />
                </IconButton>
            </IconButton>
        </div>
    );
};

export default LeanConnect;
