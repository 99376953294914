import React, { useEffect } from 'react';
import Select, { SingleValue } from 'react-select';

interface Option {
  value: string;
  label: string;
}

interface CustomizedSelectProps {
  items: Option[]; // The items to be displayed in the select dropdown
  value: string | undefined; // The selected value
  placeholder?: string; // Optional placeholder text
  handleChange: (newValue: string) => void; // Callback function to handle value change
  elementName: string
}

const CustomizedSelect: React.FC<CustomizedSelectProps> = ({ items, value, placeholder = "Select an option...", handleChange, elementName }) => {
  const onChange = (selectedOption: SingleValue<Option>) => {
    if (selectedOption) {
      handleChange(selectedOption.value);
    }
  };

  // Example of customizing the select component's styles
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      height: '30px', // Set the desired height
      minHeight: '30px', // Ensure that the minimum height is also set
    }),
  };

  return (
    <div className="select-container">
      <Select
        name={elementName}
        options={items}
        isSearchable
        placeholder={placeholder}
        styles={customStyles}
        value={items.find(option => option.value === value)}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomizedSelect;
