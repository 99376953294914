import React, { useEffect, useState } from 'react'
import './../../css/InvoiceView.css'
import Trash from './../../assets/Trash.svg'
import axios from 'axios'
import TTP from './../../assets/TTP.svg'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import QRCode from 'qrcode.react'
import { WorkspaceAddress } from '../../types/WorkspaceAddress'
import { BankInfo } from '../../types/BankInfo'
import { Workspace } from '../../types/Workspace'



interface Props {
  showQRCode: boolean;
  qrCodeDisplay: boolean;
  invoiceUrl: string;
  invoiceUniCode: string;
  selectedWorkspace: Workspace;
  bankInfo: BankInfo;
  workspaceAddress: WorkspaceAddress;
  legalName: string;
}

const InvoiceView: React.FC<Props> = ({ qrCodeDisplay, invoiceUrl, showQRCode, invoiceUniCode, bankInfo, workspaceAddress, selectedWorkspace, legalName }) => {
  // const handelHidePopup = () => {
  //   const InvoiceView = document.getElementById('invoice-popup') as HTMLElement;
  //   if (InvoiceView) {
  //     if (InvoiceView.style.display === 'block') {
  //       InvoiceView.style.display = 'none';
  //     }
  //   }
  // }

  useEffect(() => {
    console.log(bankInfo);
  }, [bankInfo]);
  const handleAddRecord = () => {
    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tbody = table.getElementsByTagName('tbody')[0];
    const row = table.getElementsByTagName('tr')[0];
    const newRow = row.cloneNode(true) as HTMLTableRowElement;
    const inputs = newRow.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].value = '';
    }

    tbody.appendChild(newRow);
  }

  // const handleInvoiceSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   try {
  //     const formData = new FormData(e.target as HTMLFormElement);

  //     const data = {};
  //     formData.forEach((value, key) => {
  //       if (key.endsWith('[]')) {
  //         const name = key.slice(0, -2); // remove the '[]'
  //         if (!data[name]) {
  //           data[name] = [];
  //         }
  //         data[name].push(value);
  //       } else {
  //         data[key] = value;
  //       }
  //     });


  //     console.clear();
  //     console.log(formData);
  //     // const data = Object.fromEntries(formData.entries());
  //     const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/create-invoice`;

  //     const response = await axios.post(url, data, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`
  //       }
  //     }
  //     )
  //     console.log(response);
  //   } catch (error) {
  //   } finally {

  //   }
  // }

  const updateTBVAT = () => {
    const inputs = document.getElementsByName('taxable_amount[]') as NodeListOf<HTMLInputElement>;
    const tbvatElement = document.querySelector('.tb-vat') as HTMLElement;

    let totalVAT = 0;

    inputs.forEach(input => {
      const value = parseFloat(input.value);
      if (!isNaN(value)) {
        const taxRate = parseFloat((input.closest('tr')!.querySelector('input[name="tax_rate"]') as HTMLInputElement).value) || 0;
        totalVAT += value * (taxRate / 100);
      }
    });
    console.clear();
    console.log(totalVAT);
    tbvatElement.textContent = totalVAT.toFixed(2); // Update the TBVAT element
  };


  const handleTaxableAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateTBVAT();
  };

  const handleRemoveRow = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent any default action and event propagation

    const target = e.target as HTMLElement;
    const table = document.getElementById('invoice-table') as HTMLTableElement;
    const tbody = table.getElementsByTagName('tbody')[0];
    const trs = tbody.getElementsByTagName('tr');
    if (trs.length > 1) {
      if (target.closest('.trash-cell')) { // Check if the click was on a trash cell
        const row = target.closest('tr') as HTMLTableRowElement;
        if (row) {
          row.remove();
        }
      }
    }
  };

  return (
    <div id='invoice-popup' className='invoice-popup-right'>
      <div className='invoice-popup-header flex-container flex-space-between'>
        <img src={process.env.REACT_APP_Laravel_API_URL_STRUCTURE + 'storage/' + selectedWorkspace.image} alt="" className='invoice-logo' />
        <div id='company-info'>
          <p>{legalName != null ? legalName : selectedWorkspace.name}</p>
          <p>{workspaceAddress ? workspaceAddress.city || 'City' : ''}, {workspaceAddress ? workspaceAddress.country || 'Country' : ''}</p>
          <p>TRN: {bankInfo.trnNumber || '-'}</p>
          <p>CR: {bankInfo.crNumber || '-'}</p>
        </div>
      </div>
      <h2 className='m-t-5 bold-text' id='popup-title'>VAT INVOICE</h2>
      {/* <form id='invoice-form' onSubmit={handleInvoiceSubmit}> */}
      {/* Invoice Info */}
      <div id="invoice-info">
        <h4>Bill To</h4>
        <div className="flex-container flex-start position-relative vertical-flex">
          <p className='w-100' id='popup-client-name'>Customer Name</p>
          {/* <p className='w-100' id='popup-address'>ADDRESS</p>
          <p className='w-100' id='popup-trn'>TRN</p> */}
          <p className='w-100' id='popup-note'></p>
          {/* <button id='add-client' onClick={handleAddClientToSelect}>+</button> */}
        </div>

        {/* <div id='sale-type' className="invoice-info-row flex-container flex-start">
          <p>Sale Type</p>
          <div className="info-section m-l-3">
            <p className='w-100'>Sale Type</p>
          </div>
        </div> */}
      </div>

      <div className="right-info float-right m-b-5">

        <div id='invoice-number' className="invoice-info-row float-right flex-container flex-start">
          <p>Invoice #</p>
          <div className="m-l-3">
            <p id='popup-invoice-number' className='w-100'>INV-######</p>
          </div>
        </div>
        <div className="clear-both"></div>
        <div id='issue-date' className="invoice-info-row float-right flex-container flex-start">
          <p>Issue Date</p>
          <div className="m-l-3">
            <p id='popup-issue-date' className='w-100'>mm/dd/yyyy</p>
          </div>
        </div>
        <div className="clear-both"></div>
        <div id='due-date' className="invoice-info-row float-right flex-container flex-start">
          <p>Due Date</p>
          <div className="m-l-3">
            <p id='popup-due-date' className='w-100'>mm/dd/yyyy</p>
          </div>
        </div>
      </div>
      {/* Invoice Table */}
      <table id="invoice-table" className='mt-4'>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>Description</th>
            <th>Code</th>
            <th>Type</th>
            <th>Qty</th>
            <th className='group relative'>
              Cost
              <span className="absolute left-0 top-full hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                Unit Cost
              </span>
            </th>
            <th className='group relative'>
              T.Amount
              <span className="absolute left-0 top-full hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                Taxable Amount
              </span>
            </th>
            <th>TAX%</th>
            <th>TAX</th>
            <th>Discount</th>
            <th className='group relative'>
              N.Amount
              <span className="absolute left-0 top-full hidden mt-1 px-2 py-1 bg-gray-700 text-white text-lg rounded group-hover:inline">
                Taxable Amount
              </span>
            </th>
          </tr>
        </thead>
        <tbody onClick={handleRemoveRow}>
          {/* <tr>
            <td className='invoice-table-td item-desc'>
              <input type="text" name='item_description[]' placeholder='Item Description' readOnly />
            </td>
            <td className='invoice-table-td qty'>
              <input type="text" name='quantity[]' placeholder='Qty' readOnly />
            </td>
            <td className='invoice-table-td rate'>
              <input type="text" name='rate[]' placeholder='Rate' readOnly />
            </td>
            <td className='invoice-table-td taxable'>
              <input type="text" name='taxable_amount[]' placeholder='Taxable Amount' onChange={handleTaxableAmountChange} readOnly />
            </td>
            <td className='invoice-table-td tax-percentage'>
              <input type="text" name='tax_rate[]' placeholder='15%' readOnly />
            </td>
            <td className='invoice-table-td tax'>
              <input type="text" name='tax[]' placeholder='TAX' readOnly />
            </td>
            <td className='invoice-table-td amount'>
              <input type="text" name="amount[]" placeholder='Amount' readOnly />
            </td>
          </tr> */}
          <tr>
            <td className='invoice-table-td product-name'>
              <input type="text" name='product_name[]' placeholder='Product Name' />
            </td>
            <td className='invoice-table-td item-desc'>
              <input type="text" name='item_description[]' placeholder='Item Description' />
            </td>
            <td className='invoice-table-td product-code'>
              <input type="text" name='product_code[]' placeholder='Code' />
            </td>
            <td className='invoice-table-td unit-type'>
              <input type="text" name='unit_type[]' placeholder='type' />
            </td>
            <td className='invoice-table-td qty'>
              <input type="text" name='quantity[]' placeholder='Qty' />
            </td>
            <td className='invoice-table-td unitCost'>
              <input type="text" name='unit_cost[]' placeholder='Unit Amount' />
            </td>
            <td className='invoice-table-td taxable'>
              <input type="text" name='taxable_amount[]' placeholder='Taxable Amount' className='inactive' readOnly />
            </td>
            <td className='invoice-table-td tax-percentage'>
              <select name="tax_rate[]" id="" className="tax-rate" >
                <option value="15">15%</option>
                <option value="0">0%</option>
              </select>
            </td>
            <td className='invoice-table-td tax'>
              <input type="text" name='tax[]' placeholder='TAX' className='inactive' readOnly />
            </td>
            <td className='invoice-table-td discount-amount'>
              <input type="text" name='discount_amount[]' className='discount' placeholder='Discount' />
            </td>
            <td className='invoice-table-td amount'>
              <input type="text" name="amount[]" placeholder='Amount' className='inactive' readOnly />
            </td>
            <td className='trash-cell overnight'>
              <button type="button" onClick={handleRemoveRow}>
                <img src={Trash} alt="trash" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="total-section flex-container flex-end m-t-3 flex-vertical-direction">
        <div className='flex-container flex-start w-100 flex-align-center'>
          <p>TOTAL TAXABLE AMOUNT</p>
          <p className='vat-total tb-vat'>0.00</p>
        </div>
        {/* <div className='flex-container flex-start w-100 flex-align-center add-discount'>
          <p>ADD A DISCOUNT</p>
        </div> */}
        <div className="flex-container flex-start w-100 flex-align-center total-vat">
          <p>TOTAL VAT</p>
          <p className='vat-total t-vat'>0.00</p>
        </div>
        <div className="flex-container flex-start w-100 flex-align-center">
          <p>TOTAL AFTER VAT</p>
          <p className='vat-total total-invoice tavat total-after-vat'>0.00</p>
        </div>
      </div>
      {/* <input type="submit" value="Create Invoice" />
      </form> */}
      <footer className='invoice-footer w-100'>
        <p>Bank Details</p>
        <div className="flex-container flex-start">
          <div className='bank-details-label-container'>
            <p>Bank Name:</p>
            <p>Account Name:</p>
            <p>Account Number:</p>
            <p>IBAN:</p>
            <p>Swift Code:</p>
            <p>Bank Address:</p>
          </div>
          <div>
            <p>{bankInfo.bankName}</p>
            <p>{bankInfo.accountName}</p>
            <p>{bankInfo.accountNumber}</p>
            <p>{bankInfo.iban}</p>
            <p>{bankInfo.swiftCode}</p>
            <p>{bankInfo.bankAddress}</p>
          </div>
        </div>
      </footer>
      {/* {
        <div className="qrcode-container">
          <img src={QRCode} alt="qr-code" className={`qr-code ${qrCodeDisplay ? '' : 'overnight'}`} />
        </div>
      } */}

      {showQRCode && (
        <div className="qr-code-container qrcode-container qr-code">
          <QRCode value={invoiceUrl} size={90} />
        </div>
      )}
    </div>
  )
}

export default InvoiceView