import { faAngleDown, faExpandArrowsAlt, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Client } from '../../types/Client';
import { ExpandMore, OpenInFull } from '@mui/icons-material';
const token = localStorage.getItem('token');

// interface Itinerary {
//   id: number;
//   name: string;
//   description: string;
//   uniItineraryCode: string;
// }

interface Itinerary {
  id: number;
  name: string;
  description: string;
  uniItineraryCode: string;
  client_name: string;
}

const ItineraryList = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState('test');
  const [alertStatus, setAlertStatus] = useState('test');
  const [loading, setLoading] = useState(false);

  const [itineraries, setItineraries] = useState<Itinerary[]>();
  const [clients, setClients] = useState<Client[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [client, setClient] = useState('');

  const navigate = useNavigate();

  const fetchInitialData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/get-itineraries`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setItineraries(data.itineraries);
        setClients(data.clients);
        setIsLoading(false);
        console.log(data.itineraries);
      } else {
        console.error('Error fetching clients');
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  }

  const navigateToDetails = (e, name, id) => {
    e.preventDefault();
    navigate(`/ttp/itinerary/${name}`);
  }

  const handleInputChange = async (id, name, value, e) => {
    e.preventDefault();
    if (itineraries) {
      setItineraries(itineraries.map((itinerary) => {
        if (itinerary.id === id) {
          return {
            ...itinerary,
            [name]: value,
          }
        }
        return itinerary;
      })
      );
    }
    // send request to update the itinerary
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-itinerary`;
    const response = await axios.post(url, {
      id,
      name,
      value,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })

    console.clear();
    console.log(response.data);
  }

  const handleShowClientList = (e: React.MouseEvent<HTMLElement>) => {
    console.clear();
    const target = e.currentTarget as HTMLElement;
    const clientList = target.nextElementSibling as HTMLElement;
    console.log(target, clientList);
    // const clientList = document.querySelector('.client-list') as HTMLElement;
    if (clientList.style.display == "") {
      // hide all .client-list except clientList
      const allClientList = document.querySelectorAll('.client-list') as NodeListOf<HTMLElement>;
      for (let i = 0; i < allClientList.length; i++) {
        const cl = allClientList[i];
        if (cl !== clientList) {
          cl.style.display = '';
        }
      }
      clientList.style.display = 'block';
    } else {
      clientList.style.display = '';
    }

  }

  // const handleShowClientForm = (e: React.MouseEvent<HTMLDivElement>) => {
  //   const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
  //   const clientList = document.querySelector('.client-list') as HTMLElement;
  //   if (clientFormPopup.style.display == "") {
  //     clientFormPopup.style.display = 'block';
  //     clientList.style.display = '';
  //   } else {
  //     clientFormPopup.style.display = '';
  //     clientList.style.display = 'block';

  //   }
  // }

  const handleShowClientForm = (e: React.MouseEvent<HTMLDivElement>) => {

    // Get the clicked element
    const target = e.target as HTMLElement;

    const clientList = target.closest('.client-list') as HTMLElement;
    const clientFormPopup = clientList.nextSibling as HTMLElement;
    if (clientFormPopup.style.display == "") {
      clientFormPopup.style.display = 'block';
      clientList.style.display = '';
    } else {
      clientFormPopup.style.display = '';
      clientList.style.display = 'block';

    }
  };


  const handleClientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    const clientListUl = document.querySelector('.client-list ul') as HTMLElement;
    const lis = clientListUl.getElementsByTagName('li');
    for (let i = 0; i < lis.length; i++) {
      const li = lis[i];
      if (li.textContent!.toLowerCase().includes(search.toLowerCase())) {
        li.style.display = 'block';
      } else {
        li.style.display = 'none';
      }
    }
  }

  const handleShowSelectedClient = (e: MouseEvent | React.MouseEvent<HTMLLIElement>) => {
    const target = e.currentTarget as HTMLLIElement;
    // const clientSection = document.querySelectorAll('.client-section') as NodeListOf<HTMLElement>;
    const clientSection = target.closest('.client-section') as HTMLElement;
    const clientElement = clientSection.querySelector('p')
    clientElement!.innerHTML = target.innerHTML.split('-')[0].trim();
    clientElement!.setAttribute('data-id', target.getAttribute('data-id')!);
    const clientList = target.closest('.client-list') as HTMLElement;
    setClient(target.innerHTML.split('-')[0].trim());
    clientList.style.display = '';
    const itineraryId = target.closest('tr')!.getAttribute('data-id');
    const clientId = target.getAttribute('data-id')!;
    updateItineraryClient(itineraryId, clientId);
  }

  const updateItineraryClient = async (itineraryId: string | null, clientId: string) => {
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/update-itinerary-client`;
    const data = { itineraryId, clientId };
    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setShowAlert(true);
      setAlertBody('Client has been updated successfully!');
      setAlertStatus('success');
    } catch (error) {
      console.error('Error updating client', error);
      setShowAlert(true);
      setAlertBody('Client has not been updated!');
      setAlertStatus('error');
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  }


  const handleHideClientForm = () => {
    const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientList = document.querySelector('.client-list') as HTMLElement;
    clientFormPopup.style.display = '';
    clientList.style.display = 'block';
  }

  const handleAddNewClient = async (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLElement;;
    // const clientFormPopup = document.querySelector('.client-form-popup') as HTMLElement;
    const clientFormPopup = target.closest('.client-form-popup') as HTMLElement;
    const targetTd = clientFormPopup.closest('.custom-cell') as HTMLElement;
    const clientList = targetTd.querySelector('.client-list') as HTMLElement;
    const name = (clientFormPopup.querySelector('.client-form-popup input[type="text"]') as HTMLInputElement).value;
    const email = (clientFormPopup.querySelector('.client-form-popup input[type="email"]') as HTMLInputElement).value;
    // !! workspace_id is hardcoded since this enterprise is for TTP only
    const data = { name: name, email: email, workspace_id: 1, customerCode: '123' };
    const clientFormSubmit = clientFormPopup.querySelector('.create-client-button') as HTMLButtonElement;
    clientFormSubmit.classList.add('inactive');
    try {
      const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/create-client`;
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const clientListUl = targetTd.querySelector('.client-list ul') as HTMLElement;
      const li = document.createElement('li');
      li.addEventListener('click', handleShowSelectedClient);
      li.textContent = name + " - " + email;
      li.setAttribute('data-id', response.data.clientId)
      clientListUl.appendChild(li);
      setShowAlert(true);
      setAlertBody('Client has been added successfully!');
      setAlertStatus('success');
    } catch (error) {
      console.error('Error creating client', error);
      setShowAlert(true);
      setAlertBody('Client has not been added!');
      setAlertStatus('error');
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    clientFormPopup.style.display = '';
    clientList.style.display = 'block';


  }

  const createItinerary = async () => {
    const table = document.querySelector('table') as HTMLTableElement;
    const tbody = table.querySelector('tbody') as HTMLTableSectionElement;
    // const tr = tbody.querySelector('tr') as HTMLTableRowElement;
    // select The last row in tbody

    // Re-attach event listeners to dynamically added elements
    // clientElement.addEventListener('click', (e) => {
    //   handleShowClientList(e as unknown as React.MouseEvent<HTMLElement>);
    // });

    // // Re-attach event listeners for each client in the dropdown
    // const clientListItems = newTr.querySelectorAll('.client-list ul li') as NodeListOf<HTMLLIElement>;
    // clientListItems.forEach((item) => {
    //   item.addEventListener('click', (e) => {
    //     handleShowSelectedClient(e as unknown as React.MouseEvent<HTMLLIElement>);
    //   });
    // });

    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/ttp-itinerary/create-itinerary`;
    const response = await axios.post(url, {
      name: 'New Itinerary',
      description: 'New Description',
      client_id: 1,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    console.clear();
    console.log(response.data);
    // setItineraries([...(itineraries || []), response.data.itinerary]);
    // append new itinerary to the list
    setItineraries([...(itineraries || []), response.data.itinerary]);
    // const newTr = tbody.lastElementChild as HTMLTableRowElement
    // const clientSection = newTr.querySelector('.client-section') as HTMLElement;
    // const clientElement = clientSection.querySelector('p') as HTMLElement;
    // clientElement.innerHTML = 'SELECT FROM CLIENTS';
    // console.log(clientElement);
    // clientElement.setAttribute('data-id', '');

  }
  useEffect(() => {
    fetchInitialData();
  }, []);
  return (
    <>
      <table className="min-w-full bg-white">
        <thead className="bg-[var(--grassy)] border-b-2 border-gray-200 sticky top-0 z-20">
          <tr>
            <th className={`i-no-border px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase w-[1rem]`}>
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              name
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              description
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              customer
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              REF-UNIQUE
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              INVOICE DATE
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              Y- VALUE
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              VALUE DATE
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              VERTICALS
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              CUR
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              TOTAL
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              VAT
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              INCOMING
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              RECEIVABLE
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              COLL. SOURCE
            </th>
            <th className={`i-border-r px-4 py-3 font-medium text-left text-gray-600 min-w-fit tracking-wider whitespace-nowrap text-xl uppercase`}>
              COLLECTION STATUS
            </th>
          </tr>
        </thead>
        <tbody>
          {itineraries &&
            itineraries.map((itinerary, index) => (
              <tr
                key={index}
                className="even:bg-gray-50 hover:bg-blue-50 transition-colors cursor-pointer"
                data-id={itinerary.id}
              >
                <td className='custom-cell relative action-td border-r-0 h-[39px]'>
                  <input type="checkbox" name="" id="" />
                  {/* <FontAwesomeIcon icon={faAngleDown} className='expand-icon pointer' /> */}
                  {/* <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className='expand-icon' onClick={(e) => navigateToDetails(e, itinerary.uniItineraryCode, itinerary.id)} /> */}

                  <ExpandMore />
                  <OpenInFull className='expand-icon' onClick={(e) => navigateToDetails(e, itinerary.uniItineraryCode, itinerary.id)} />
                </td>
                <td className="custom-cell border-r-black border-l-0 border-r-1" data-name="name">
                  <input
                    type="text"
                    value={itinerary.name}
                    data-name='name'
                    onChange={(e) => handleInputChange(itinerary.id, 'name', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />

                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value={itinerary.description}
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1 border-r-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="customer">
                  <div className="info-section client-section position-relative w-[20rem]">
                    <p className='w-100' onClick={handleShowClientList}>{itinerary.client_name != '' ? itinerary.client_name : 'SELECT FROM CLIENTS'}</p>
                    <div className="client-list rounded-radius">
                      <div className='add-new-client' onClick={handleShowClientForm}>+ ADD A NEW CLIENT</div>
                      <input type="text" name="" id="search-client" placeholder='Search...' onChange={handleClientSearch} />
                      <ul>
                        {clients &&
                          clients.map(client => (
                            <li key={client.id} data-id={client.id} onClick={handleShowSelectedClient}>{client.name} - {client.email}</li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className="client-form-popup client-list rounded-radius">
                      <span className='client-form-close' onClick={handleHideClientForm}>X</span>
                      <div className='m-t-3'>
                        <input type="text" placeholder='Full Name' />
                        <br />
                        <input type="email" placeholder='Email' />
                        <br />
                        <input type="button" className='clickable create-client-button' value="CREATE CLIENT" onClick={handleAddNewClient} />
                      </div>
                    </div>
                  </div>
                </td>

                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
                <td className="custom-cell border-r-1" data-name="description">
                  <input
                    type="text"
                    value=''
                    data-name='description'
                    onChange={(e) => handleInputChange(itinerary.id, 'description', e.target.value, e)}
                    className="border-none outline-none bg-transparent w-[90%] ml-1"
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className='create-invoice create-button clickable pointer' onClick={createItinerary}>Create Itinerary</div>
    </>
  );
}
export default ItineraryList