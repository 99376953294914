// src/components/AccountProfile.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

interface Balance {
  id: number;
  account_id: number;
  amount: number;
  currency: string;
  type: string;
  date_time: string;
}

interface Transaction {
  id: number;
  account_id: number;
  lean_transaction_id: string;
  description: string;
  amount: number;
  currency: string;
  date: string;
  type: string;
  category: string;
}

interface Account {
  id: number;
  account_id: string;
  entity_id: string;
  description: string;
  nickname: string;
  // Other account fields...
}

interface LocationState {
  entityId: string;
}

const AccountProfile: React.FC = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const location = useLocation();
  const state = location.state as LocationState;
  const entityId = state?.entityId;

  const [account, setAccount] = useState<Account | null>(null);
  const [balances, setBalances] = useState<Balance[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loadingAccount, setLoadingAccount] = useState<boolean>(true);

  const workspace = localStorage.getItem('workspace');

  useEffect(() => {
    fetchAccountDetails();
  }, [accountId]);

  const fetchAccountDetails = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post<Account>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/accounts/${accountId}`,
        {
          workspace: workspace,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAccount(response.data);
      setLoadingAccount(false);

      // Fetch balances and transactions
      fetchBalances();
      fetchTransactions();
    } catch (error) {
      console.error('Error fetching account details:', error);
      setLoadingAccount(false);
    }
  };

  const fetchBalances = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post<Balance[]>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/accounts/${accountId}/balances`,
        {
          workspace: workspace,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBalances(response.data || []);
    } catch (error) {
      console.error('Error fetching balances:', error);
    }
  };

  const fetchTransactions = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post<Transaction[]>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/accounts/${accountId}/transactions`,
        {
          workspace: workspace,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactions(response.data || []);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const updateBalances = async () => {
    const token = localStorage.getItem('token');

    try {
      // Fetch balances from Lean API and save to database
      await axios.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/lean/accounts/${accountId}/balances`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            entity_id: entityId,
            workspace: workspace,
          },
        }
      );
      // Refresh balances from database
      fetchBalances();
    } catch (error) {
      console.error('Error updating balances:', error);
    }
  };

  const updateTransactions = async () => {
    const token = localStorage.getItem('token');

    try {
      // Fetch transactions from Lean API and save to database
      await axios.get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/lean/accounts/${accountId}/transactions`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          params: {
            entity_id: entityId,
            workspace: workspace,
          },
        }
      );
      // Refresh transactions from database
      fetchTransactions();
    } catch (error) {
      console.error('Error updating transactions:', error);
    }
  };

  if (loadingAccount) {
    return <div>Loading account...</div>;
  }

  if (!account) {
    return <div>Account not found.</div>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Account {account.account_id}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Description: {account.description || account.nickname}
      </Typography>
      <Button variant="contained" color="primary" onClick={updateBalances} style={{ marginRight: '8px' }}>
        Update Balances
      </Button>
      <Button variant="contained" color="primary" onClick={updateTransactions}>
        Update Transactions
      </Button>

      <Typography variant="h5" gutterBottom style={{ marginTop: '16px' }}>
        Balances
      </Typography>
      {balances.length === 0 ? (
        <Typography>No balances available.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="balances table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Date Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {balances.map((balance) => (
                <TableRow key={balance.id}>
                  <TableCell>{balance.type}</TableCell>
                  <TableCell>{balance.amount}</TableCell>
                  <TableCell>{balance.currency}</TableCell>
                  <TableCell>{new Date(balance.date_time).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Typography variant="h5" gutterBottom style={{ marginTop: '16px' }}>
        Transactions
      </Typography>
      {transactions.length === 0 ? (
        <Typography>No transactions available.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="transactions table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Category</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{new Date(transaction.date).toLocaleString()}</TableCell>
                  <TableCell>{transaction.description}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{transaction.currency}</TableCell>
                  <TableCell>{transaction.type}</TableCell>
                  <TableCell>{transaction.category}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default AccountProfile;
