import React, { useEffect } from 'react'
import { Itinerary } from '../../types/Itinerary'

interface Prop {
    itinerary: Itinerary;
}
const ItineraryExpand = ({ itinerary }) => {

    useEffect(() => {
        // console.clear();
        console.log(itinerary);
        // console.log(itinerary.vendorCostNetVat);
    }, [itinerary]);

    return (
        <>
            <div id='itinerary-expand'>
                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            Unit
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.number_of_units.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            VAT RATE
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary && itinerary.vatable === "VATABLE" ? '15%' : '0%'} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            Vendor Cost - Net Vat
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.vendorCostNetVat.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            Vendor Cost VAT
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.vendorCostVat.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            PAY VENDOR
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.payVendor.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            Guest Cost - NET VAT
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.guestCostNetVat.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            Guest Cost VAT
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.guestCostVat.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            Mark Up VALUE
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.markupValue.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            Mark Up VAT
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.markupVat.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            GUEST BILLING AMOUNT
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.guestBillingAmount.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            GROSS CONTRIBUTION MARGIN
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.grossContributionMargin.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            TOTAL VAT
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.totalVat.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>

                <div className="flex-container mt-4">
                    <div className="flex-1-3 flex-container flex-align-center">
                        <p>
                            PRICE VAT
                        </p>
                    </div>
                    <div className="flex-2-3">
                        <input type="text" name="trn" className='form-control w-100' value={itinerary ? itinerary.priceVat.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : ''} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItineraryExpand