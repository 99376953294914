// src/components/LeanSuccess.tsx

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const LeanSuccess: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const entityId = queryParams.get('entity_id');
    const bankIdentifier = queryParams.get('bank_identifier');

    if (entityId && bankIdentifier) {
      // Make an API call to the backend to process the new bank connection
      processBankConnection(entityId, bankIdentifier);
    } else {
      // Handle the error case
      alert('Missing entity_id or bank_identifier in the URL parameters.');
      navigate('/'); // Redirect to home or an error page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once on component mount

  const processBankConnection = async (entityId: string, bankIdentifier: string) => {
    const token = localStorage.getItem('token');
    const workspace = localStorage.getItem('workspace');

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/lean/bank-connections`,
        {
          entity_id: entityId,
          bank_identifier: bankIdentifier,
          workspace: workspace,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // After processing, navigate to the list of bank connections or accounts
      // navigate(`/bank-connections/${entityId}/accounts`);
      navigate(`/banks`);
    } catch (error) {
      console.error('Error processing bank connection:', error);
      alert('Failed to process the bank connection.');
      navigate('/'); // Redirect to home or an error page
    }
  };

  return (
    <div>
      <p>Processing bank connection...</p>
    </div>
  );
};

export default LeanSuccess;
