import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import NavigationTabs from '../layouts/NavigationTabs';

interface BankTransaction {
  [key: string]: string;
}

const BankTransaction = () => {
  const { bankName, workspaceUniCode } = useParams();
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);
  const [bankDataHeader, setBankDataHeader] = useState([]);
  const [bankData, setBankData] = useState<BankTransaction[]>([]);

  const fetchBankTransactions = async () => {
    setLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/get-bank-transactions/${workspaceUniCode}/${bankName}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      const bankData = response.data.bankData.original.records;
      setBankData(bankData);
      setBankDataHeader(response.data.bankData.original.headers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bank transactions:', error);
    }
  }

  useEffect(() => {
    fetchBankTransactions();
  }, []);

  const tabs = [
    { label: 'INMA', url: '/banks', status: 'clickable' },
    { label: 'HALA', url: `/banks/${workspaceUniCode}/HALA`, status: 'clickable' },
  ];

  return (
    <>
      <NavigationTabs tabs={tabs} classes='bg-[--dark-gray] top-[0] z-30 sticky' />
      {
        loading ? (
          <span className="ml-4 text-blue-300 bold-text">Loading...</span>
        ) : (
          <table className='w-full'>
            <thead>
              <tr className='bg-[--black] text-white top-[4rem] z-20 sticky'>
                {bankDataHeader.map((header) => (
                  <th key={header} className='min-w-[12rem]'>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bankData.map((record, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  {bankDataHeader.map((header) => (
                    <td
                      key={`${index}}`}
                      className="border border-gray-200 px-4 py-2"
                    >
                      {header != 'INV' ? record.fields?.[header] || 'N/A' : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )
      }
    </>
  )
}

export default BankTransaction