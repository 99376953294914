import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
} from '@mui/material';
import FilterList from '@mui/icons-material/FilterList';
import Sort from '@mui/icons-material/Sort';
import ExpandMore from '@mui/icons-material/ExpandMore';
import OpenInFull from '@mui/icons-material/OpenInFull';

import { useNavigate } from 'react-router-dom';

import LeanConnect from '../Lean/LeanConnect';
import NavigationTabs from '../layouts/NavigationTabs';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { BankConnection, Transaction, BankAccount, ApiResponse } from '../interfaces';
import { addCommaToWholeNumber } from '../../utilities/helper';
import TransactionCell from './TransactionCell';
import '../../css/lean-style.scss';

const ConnectedBanksList: React.FC = () => {
  const workspaceUniCode = useSelector((state: RootState) => state.workspaceState.selectedWorkspace.uniCode);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [bankConnections, setBankConnections] = useState<BankConnection[]>([]);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const workspace = localStorage.getItem('workspace');

  useEffect(() => {
    fetchBankConnections();
    fetchTransactions();
  }, []);

  const fetchBankConnections = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post<ApiResponse>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/bank-connections`,
        {
          workspace: workspace,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBankConnections(response.data.bankConnections);
      setBankAccounts(response.data.bankAccounts);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching bank connections:', error);
      setLoading(false);
    }
  };

  const fetchTransactions = async () => {
    const token = localStorage.getItem('token');
    const workspace = localStorage.getItem('workspace');

    try {
      const response = await axios.post<{ transactions: Transaction[] }>(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/transactions`,
        {
          workspace: workspace,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTransactions(response.data.transactions);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleDeleteBankConnection = async (entityId: string) => {
    if (window.confirm('Are you sure you want to disconnect this bank?')) {
      const token = localStorage.getItem('token');

      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/bank-connections/${entityId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            data: {
              reason: 'User requested disconnection',
            },
          }
        );
        // Remove the bank connection from the list
        setBankConnections((prev) =>
          prev.filter((connection) => connection.entity_id !== entityId)
        );
        // Remove related transactions
        setTransactions((prev) =>
          prev.filter((transaction) => transaction.account.bankConnection.entity_id !== entityId)
        );
      } catch (error) {
        console.error('Error deleting bank connection:', error);
        alert('Failed to disconnect the bank.');
      }
    }
  };

  const navigateToAccounts = (bankConnection: BankConnection) => {
    navigate(`/bank-connections/${bankConnection.entity_id}/accounts`);
  };

  // const tabs = [
  //   { label: 'INMA', url: '/banks', status: 'clickable' },
  //   { label: 'HALA', url: `/banks/${workspaceUniCode}/HALA`, status: 'clickable' },
  // ];

  const tabs = bankAccounts.map((bank) => ({
    label: bank.name.toUpperCase(),
    url: `/banks/${workspaceUniCode}/${bank.name.toUpperCase()}`,
    status: "clickable",
  }));

  if (loading) {
    return (
      <>
        <NavigationTabs tabs={tabs} classes='bg-[--dark-gray]' />
        <p className="ml-4">Loading transactions...</p>
      </>
    );
  }

  return (
    <>
      <NavigationTabs tabs={tabs} classes='bg-[--dark-gray]' />
      <LeanConnect />

      {/* Connected Banks Table */}
      {/* <TableContainer component={Paper}>
        <Table aria-label="connected banks table">
          <TableHead>
            <TableRow>
              <TableCell>Bank Identifier</TableCell>
              <TableCell>Bank Type</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bankConnections.map((bankConnection) => (
              <TableRow key={bankConnection.entity_id}>
                <TableCell component="th" scope="row">
                  {bankConnection.bank_identifier}
                </TableCell>
                <TableCell>{bankConnection.bank_type}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigateToAccounts(bankConnection)}
                    style={{ marginRight: '8px' }}
                  >
                    View Accounts
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteBankConnection(bankConnection.entity_id)}
                  >
                    Delete Bank Connection
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}

      {/* Transactions Table */}
      <TableContainer component={Paper} style={{ maxHeight: '100vh', marginTop: '0px', overflowY: 'auto' }}>
        <Table stickyHeader>
          {/* Table Header */}
          <TableHead>
            <TableRow className="table-header">
              <TableCell padding="checkbox" />
              <TableCell padding="none" />
              <TableCell padding="none" />
              <TableCell>
                TX REF
              </TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Counterpart</TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id} className="table-row">
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell padding="none">
                  <IconButton>
                    <ExpandMore />
                  </IconButton>
                </TableCell>
                <TableCell padding="none">
                  <IconButton>
                    <OpenInFull />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <TransactionCell transaction={transaction} />
                </TableCell>
                <TableCell>{new Date(transaction.value_date_time).toLocaleDateString()}</TableCell>
                <TableCell>
                  {transaction.transaction_information?.length > 70
                    ? `${transaction.transaction_information.slice(0, 70)}...`
                    : transaction.transaction_information}
                </TableCell>
                <TableCell>
                  <span className="currency-badge">{transaction.currency}</span>
                </TableCell>
                <TableCell>
                  {transaction.credit_debit_indicator === 'CREDIT'
                    ? `-${addCommaToWholeNumber(transaction.amount)}`
                    : addCommaToWholeNumber(transaction.amount)}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </>
  );
};

export default ConnectedBanksList;
