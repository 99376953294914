import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';

interface DayDropdownProps {
  items: string[];
  selectedItem?: string;
  defaultSelectedItem?: string;
  onChange: (selectedItem: string) => void;
  otherClasses?: string;
  fieldName?: string;
}

const Dropdown: React.FC<DayDropdownProps> = ({ items, selectedItem, defaultSelectedItem = '', onChange, otherClasses, fieldName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<string | null>(selectedItem || defaultSelectedItem);

  useEffect(() => {
    // alert('selectedItem: ' + selectedItem + ' defaultSelectedItem: ' + defaultSelectedItem);
    setCurrentItem(selectedItem || defaultSelectedItem);
  }, [selectedItem]);

  const handleSelect = (item: string) => {
    setCurrentItem(item);
    onChange(item);
    setIsOpen(false);
  };

  const optionBgColorLight = 'bg-white';
  const optionBgColorDark = 'bg-white';

  return (
    <div className="relative inline-block text-left w-full">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`min-w-[84px] bg-gray-200 rounded-2xl shadow-sm px-2 py-2 text-gray-700 h-10 font-medium focus:outline-none ${otherClasses} ${fieldName && fieldName === 'category' ? 'text-[8px]' : ''}`}
      >
        {currentItem}
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-2 min-w-fit bg-transparent rounded-md">
          <ul className="py-1 bg-transparent">
            {items.map((item, index) => (
              <li
                key={index}
                onClick={() => handleSelect(item)}
                className={`cursor-pointer px-2 py-2 text-black ${index % 2 === 0 ? optionBgColorLight : optionBgColorDark} 
                border border-gray-500 rounded-2xl mb-1 hover:bg-opacity-75 ${otherClasses} ${fieldName && fieldName === 'category' ? 'text-[8px]' : ''}`}

              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
