import React, { useState, useEffect } from 'react';

interface DayDropdownProps {
  days: string[];
  selectedDay?: string;
  defaultSelectedDay?: string; // Optional prop
  onChange: (selectedDay: string) => void;
}

const DayDropdown: React.FC<DayDropdownProps> = ({
  days,
  selectedDay,
  defaultSelectedDay = '', // Default value if not provided
  onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState(defaultSelectedDay || selectedDay);

  useEffect(() => {
    setCurrentDay(selectedDay || defaultSelectedDay);
  }, [selectedDay]);

  const handleSelect = (day: string) => {
    setCurrentDay(day);
    onChange(day);
    setIsOpen(false);
  };

  const optionBgColorLight = 'bg-violet-300';
  const optionBgColorDark = 'bg-violet-400';

  return (
    <div className="relative inline-block text-left w-full">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-32 bg-gray-200 rounded-2xl shadow-sm px-2 py-2 text-gray-700 text-lg h-10 font-medium focus:outline-none"
      >
        {currentDay && currentDay !== 'ADDITIONAL' ? `DAY ${currentDay}` : 'ADDITIONAL'}
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-2 w-32 bg-transparent rounded-md">
          <ul className="py-1 bg-transparent">
            {days.map((day, index) => (
              <li
                key={index}
                onClick={() => handleSelect(day)}
                className={`cursor-pointer px-2 py-2 text-white ${index % 2 === 0 ? optionBgColorLight : optionBgColorDark
                  } rounded-2xl mb-1 hover:bg-opacity-75`}
              >
                {day !== 'ADDITIONAL' ? `DAY ${day}` : day}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DayDropdown;
